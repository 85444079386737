/*** Resources ***/

.Docs .resources {
  width: 260px;
  padding: 20px;
  font-size: 12px;
  background: #fafafa;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  border-radius: 5px 5px 0 0;
  border: 2px solid #eee;
  border-bottom: 0;
}

.Docs .resources .error,
.Docs .resources .loading,
.Docs .resources .empty,
.Docs .resources a {
  padding: 2px 10px;
}

.Docs .resources a {
  display: block;
  color: inherit;
  text-decoration: none;
  transition: font-weight .2s;
  background: none !important;
  border-radius: 2px;
}

.Docs .resources a:hover,
.Docs .resources a.active {
  background-color: #eee !important;
}

.Docs .resources h2 {
  margin-top: 0;
}

.Docs .resources h2 a {
  margin: 0 0 5px;
}

.Docs .resources .endpoint {
  margin-top: 7px;
}

.Docs .resources .method {
  margin-left: 7px;
}

/*** Documentation ***/

.Docs .documentation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 300px - 60px);
  overflow: auto;
  overflow-x: hidden;
  padding: 30px;
}

.Docs .documentation section {
  margin: 60px 0;
}

.Docs .documentation .method {
  color: #fff;
  display: inline-block;
  padding: 3px 7px;
  border-radius: 5px;
}

.Docs table {
  font-size: 85%;
}

.Docs table tr td:nth-child(2) {
  text-align: center;
}

.Docs p code,
.Docs pre {
  background: #fafafa;
  font-size: 85%;
  border: 2px solid #eee;
}

.Docs p code {
  padding: .13em .33em;
  border-radius: 3px;
  border-width: 1px;
}

.Docs pre {
  line-height: 1.45;
  padding: 16px;
  overflow: auto;
  border-radius: 5px;
}

/*** Method Colors ***/

.Docs a.GET,
.Docs .GET {
  color: #3eb63e;
  background: #3eb63e;
}

.Docs a.POST,
.Docs .POST {
  color: #f5a623;
  background: #f5a623;
}

.Docs a.PUT,
.Docs .PUT {
  color: #4a90e2;
  background: #4a90e2;
}

.Docs a.PATCH,
.Docs .PATCH {
  color: #666;
  background: #666;
}

.Docs a.DELETE,
.Docs .DELETE {
  color: #ed4b48;
  background: #ed4b48;
}

/*** Footer ***/

.Docs .Footer {
  margin: 30px 0 0;
}

.loading + .Footer,
.empty + .Footer {
  position: absolute;
  bottom: 30px;
  width: calc(100% - 60px);
}
