body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2c3e50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  -webkit-appearance: none;
  background: none;
  border: 1px solid #ccc;
  outline: none;
  text-align: left;
  color: inherit;
  font-size: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  display: block;
  font-size: 2em;
  margin: .67em 0;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin: .83em 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin: 1em 0;
  font-weight: bold;
}

h4 {
  display: block;
  font-size: inherit;
  margin: 1.33em 0;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: .83em;
  margin: 1.67em 0;
  font-weight: bold;
}

.Footer {
  font-size: 14px;
  color: #999;
  text-align: center;
  height: 20px;
}
