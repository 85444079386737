.ConsolePage .container {
  position: relative;
  max-width: 1200px;
  width: 100%;
  height: calc(100vh - 70px);
  margin: auto;
}

.ConsolePage h1:first-of-type,
.ConsolePage h1:first-of-type + section {
  padding-top: 0;
  margin-top: 0;
}

.ConsolePage p,
.ConsolePage pre,
.ConsolePage form,
.ConsolePage table {
  margin: 16px 0;
}

/*** Table ***/

.ConsolePage table {
  /* background: #fafafa; */
  border-radius: 5px;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px #eee;
  overflow: hidden;
}

.ConsolePage th,
.ConsolePage td {
  border: 1px solid #eee;
  padding: 5px 10px;
}

.ConsolePage th {
  background: #fafafa;
  font-weight: 600;
}

.ConsolePage th:last-of-type {
  border-right-color: #eee;
}

.ConsolePage td[colSpan] {
  text-align: center !important;
}
