.Overview {
  max-width: 840px;
  min-height: calc(100% - 30px - 50px);
  margin: auto;
  padding: 30px 30px 50px;
  position: relative;
}

.Overview section {
  margin: 60px 0;
}

.Overview a {
  color: inherit;
}

/*** KeyCreate ***/

.KeyCreate {
  text-align: center;
}

.KeyCreate input {
  margin-left: 10px;

  background: none;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 5px 0 0 5px;
  outline: none;
  padding: 7px 10px;
  width: calc(300px);
  text-align: left;
  color: inherit;
  font-size: 85%;
}

.KeyCreate input::placeholder {
  opacity: 0.3;
}

.KeyCreate button {
  display: inline-block;
  background: rgba(63, 154, 247, 1);
  border-radius: 0 5px 5px 0;
  padding: 7px 10px;
  margin: 0;
  color: #fff;
  border: 1px solid rgba(63, 154, 247, 1);
  outline: none;
  font-size: 85%;
  font-weight: bold;
  cursor: pointer;
}

.KeyCreate .error {
  color: rgba(231, 76, 60, 1);
  font-size: 85%;
  margin: 10px 0;
}

/*** Table ***/

.Overview table {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
  text-align: center;
}

.Overview table tr td:first-of-type {
  text-align: left;
}

.Overview table td {
  padding: 15px 20px;
}

.Overview table td[colSpan] {
  font-size: 85%;
}

.Overview table .bookmark td {
  padding: 0;
}

.Overview table .bookmark span {
  display: inline-block;
  cursor: pointer;
  background: #fafafa;
  border: 1px solid #eee;
  padding: 1px 7px;
  margin: 10px;
  border-radius: 3px;
}

.Overview table .bookmark span:hover {
  background: #eee;
}

/*** Footer ***/

.Overview .Footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}
